import { OTLPTraceExporter } from "@opentelemetry/exporter-trace-otlp-http";
import {
  WebTracerProvider,
  BatchSpanProcessor,
  ConsoleSpanExporter,
} from "@opentelemetry/sdk-trace-web";
import { ZoneContextManager } from "@opentelemetry/context-zone";
import { Resource } from "@opentelemetry/resources";
import { ATTR_SERVICE_NAME } from "@opentelemetry/semantic-conventions";
// import { registerInstrumentations } from "@opentelemetry/instrumentation";
// import { getWebAutoInstrumentations } from "@opentelemetry/auto-instrumentations-web";
import { Span, SpanStatusCode, trace } from "@opentelemetry/api";
import { getUid, getVersionNumber } from "./system";
import { Feature, isFeatureEnabled } from "./features";
import { Middleware, PayloadAction } from "@reduxjs/toolkit";

// The exporter is responsible for sending traces from the browser to your collector

const exporter = new OTLPTraceExporter({
  headers: {
    "x-honeycomb-team": import.meta.env.VITE_HONEYCOMB_API_KEY!,
  },
  url: "https://api.honeycomb.io/v1/traces",
});

const spanProcessors = [new BatchSpanProcessor(exporter)];

if (
  import.meta.env.MODE !== "production" &&
  import.meta.env.VITE_ENABLE_DEBUG_TRACING !== "false"
) {
  const consoleExporter = new ConsoleSpanExporter();
  spanProcessors.push(new BatchSpanProcessor(consoleExporter));
}

// The TracerProvider is the core library for creating traces
const provider = new WebTracerProvider({
  resource: new Resource({
    [ATTR_SERVICE_NAME]: "browser",
  }),
  spanProcessors,
});

// A context manager allows OTel to keep the context of function calls across async functions
// ensuring you don't have disconnected traces
provider.register({
  contextManager: new ZoneContextManager(),
});

// const addUserIdAttributesToSpan = (span: Span) => {
//   const userId = getUid();
//   if (userId) {
//     span.setAttribute("user.id", userId);
//   }
// };

// registerInstrumentations({
//   instrumentations: [
//     getWebAutoInstrumentations({
//       "@opentelemetry/instrumentation-document-load": {
//         applyCustomAttributesOnSpan: {
//           documentLoad: addUserIdAttributesToSpan,
//         },
//       },
//       "@opentelemetry/instrumentation-fetch": {
//         ignoreUrls: [
//           /https:\/\/api-js\.mixpanel\.com\/track/g,
//           /https:\/\/sdk\.split\.io/g,
//           /https:\/\/auth\.split\.io/g,
//         ],
//         propagateTraceHeaderCorsUrls: [
//           /https:\/\/hangfive.+/g, //Regex to match your backend urls. This should be updated.
//           /https:\/\/api\.hangfive\.com.+/g,
//         ],
//       },
//       "@opentelemetry/instrumentation-user-interaction": {
//         enabled: false,
//       },
//       "@opentelemetry/instrumentation-xml-http-request": {
//         ignoreUrls: [
//           /https:\/\/api-js\.mixpanel\.com\/track/g,
//           /https:\/\/sdk\.split\.io/g,
//           /https:\/\/pagead2\.googlesyndication\.com/g,
//         ],
//       },
//     }),
//   ],
// });

type SpanParams = {
  message?: string;
  spanName?: string;
};

export const logInfo = (
  params: SpanParams &
    Record<string, string | boolean | number | null | undefined> = {}
) => {
  isFeatureEnabled(Feature.VerboseLogging).then(
    (verboseLoggingFeatureEnabled) => {
      const { spanName, message, ...customParams } = params;
      if (verboseLoggingFeatureEnabled) {
        trace
          .getTracer("trace")
          .startActiveSpan(spanName ?? "info", (span: Span) => {
            for (const [key, value] of Object.entries(customParams)) {
              span.setAttribute(`error.${key}`, JSON.stringify(value));
            }

            span.setStatus({
              code: SpanStatusCode.UNSET,
              message: message ?? "Info",
            });

            span.setAttribute("user.id", getUid() ?? "unknown");
            span.setAttribute("app.version", getVersionNumber());

            // Report the error to OpenTelemetry
            span.end();

            console.log("INFO: " + (message ?? "Info"), customParams, {
              verboseLoggingFeatureEnabled,
            });
          });
      }
    }
  );
};

const logError = (
  error: unknown,
  params: SpanParams &
    Record<string, string | boolean | number | null | undefined> = {}
) => {
  const { message, spanName, ...customParams } = params;

  trace
    .getTracer("trace")
    .startActiveSpan(spanName ?? "error_handling", (span: Span) => {
      // if (parentSpan) {
      //   span = parentSpan.addEvent(spanName ?? "error_handling");
      // } else {
      //   span = trace
      //     .getTracer("error_handling")
      //     .startSpan(spanName ?? "error_handling");
      // }

      if (error instanceof Error) {
        // Add attributes or information related to the error
        span.setAttribute("error.comment", error.message);
        span.setAttribute("error.name", error.name);

        if (error.stack) {
          span.setAttribute("error.stack", error.stack);
        }

        if (error.cause) {
          span.setAttribute("error.cause", JSON.stringify(error.cause));
        }

        // Set the span's status to indicate an error
        span.setStatus({
          code: SpanStatusCode.ERROR,
          message: message ?? error.message ?? "Unknown error",
        });
      } else {
        span.setAttribute("error", JSON.stringify(error));
        span.setStatus({
          code: SpanStatusCode.ERROR,
          message: message ?? "Unknown error",
        });
      }

      for (const [key, value] of Object.entries(params)) {
        span.setAttribute(`error.${key}`, JSON.stringify(value));
      }

      span.setAttribute("user.id", getUid() ?? "unknown");
      span.setAttribute("app.version", getVersionNumber());

      // Report the error to OpenTelemetry
      span.end();

      console.error(message ?? "Unknown error", error, customParams);
    });
};

const isPayloadAction = (action: unknown): action is PayloadAction =>
  (action as PayloadAction).payload !== undefined &&
  (action as PayloadAction).type !== undefined;

export const actionLogger: Middleware = (api) => (next) => async (action) => {
  const isVerboseLoggingEnabled = await isFeatureEnabled(
    Feature.VerboseLogging
  );

  if (isVerboseLoggingEnabled) {
    trace.getTracer("trace").startActiveSpan("action", (span: Span) => {
      span.setAttribute("user.id", getUid() ?? "unknown");
      span.setAttribute("app.version", getVersionNumber());
      if (isPayloadAction(action)) {
        span.setAttribute("action.type", action.type);
        span.setAttribute("action.payload", JSON.stringify(action.payload));
      }

      span.addEvent("Action dispatched", {});

      const result = next(action);

      span.setAttribute("newState", JSON.stringify(api.getState()));

      span.end();

      return result;
    });
  } else {
    return next(action);
  }
};

export { logError };
