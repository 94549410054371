import {
  saveGameToLocalStorage,
  saveSettingsToLocalStorage,
  saveStatsToLocalStorage,
} from "../helpers/persistence";
import GameStatus from "../types/GameState";
import {
  clearGame,
  postProgress,
  selectDailyStats,
  selectGameIndex,
  selectGameStatus,
  selectIsLearning,
  selectLetterPresses,
  selectPuzzle,
  selectStats,
} from "./gameSlice";
import { selectGameIndex as selectLearningGameIndex } from "./learningSlice";
import { observeStore } from "./observeStore";
import { calculateStats, postStats } from "./statsSlice";
import { store } from "./store";
import { track } from "./trackingSlice";
import {
  clearLetterPress as clearLetterPressHelper,
  clearGame as clearGameHelper,
} from "../helpers/persistence";
import { logInfo } from "../helpers/tracing";
import { postNativeBridgeMessage } from "../helpers/nativeBridge";

export const registerObservers = () => {
  observeStore(store, selectGameStatus, (gameStatus, prevGameStatus) => {
    console.log(`Game status changed from ${prevGameStatus} to ${gameStatus}`);
    logInfo({
      message: `Game status changed from ${prevGameStatus} to ${gameStatus}`,
    });
    if (
      prevGameStatus === GameStatus.IN_PROGRESS &&
      gameStatus !== prevGameStatus
    ) {
      const gameNumber = store.getState().game.puzzle.gameIndex;
      const gameResult = gameStatus === GameStatus.SOLVED ? "Won" : "Lost";

      const isLearning = selectIsLearning(store.getState());
      const learningIndex = selectLearningGameIndex(store.getState());

      const totalGames = selectDailyStats(store.getState())?.playCount;

      // Track it
      store.dispatch(
        track({
          eventName: isLearning ? "finish_tutorial" : "finish_game",
          properties: {
            gameCount: (totalGames ?? 0) + 1,
            gameIndex: isLearning ? learningIndex : gameNumber,
            gameResult,
          },
        })
      );

      if (totalGames === 0) {
        store.dispatch(
          track({
            eventName: "finish_first_game",
          })
        );
      }

      console.log("game completed, dispatch calculate stats");
      store.dispatch(calculateStats());

      postNativeBridgeMessage("finishGame");
    }
  });

  observeStore(store, selectDailyStats, (stats, prevStats) => {
    console.log("stats changed", { prevStats, stats });

    const shouldPostStats =
      (prevStats && stats?.latestGameNumber !== prevStats.latestGameNumber) ??
      false;

    const isLearning = selectIsLearning(store.getState());
    const gameIndex = selectGameIndex(store.getState());

    if (isLearning || gameIndex >= 10000) {
      console.log("It's a learning game");
      return;
    }

    // This gets called twice. Once from undefined, and once from the empty state to this one, I think
    // First one is called when setting initial state, second is when migrate action happens.
    // This is probably not the way to have done this

    if (shouldPostStats) {
      console.log("stats changed to ", stats);

      store.dispatch(postStats());
    }
  });

  // Persist stats to localStorage
  observeStore(store, selectStats, (stats) => {
    if (stats && !selectIsLearning(store.getState())) {
      saveStatsToLocalStorage(stats);
    }
  });

  // Persist game but not game.puzzle to localStorage
  observeStore(
    store,
    (state) => state.game,
    (game) => {
      if (game && !game.isLearning) {
        saveGameToLocalStorage(game);
      }
    }
  );

  // Persist settings to localStorage
  observeStore(
    store,
    (state) => state.settings,
    (settings) => {
      if (settings) {
        saveSettingsToLocalStorage(settings);
      }
    }
  );

  // Clear game if puzzle index changes
  observeStore(store, selectGameIndex, (gameIndex, prevGameIndex) => {
    console.log("puzzle index changed", { gameIndex, prevGameIndex });
    if (prevGameIndex !== undefined && gameIndex !== prevGameIndex) {
      const isLearning = selectIsLearning(store.getState());
      const wasLearning = prevGameIndex > 10000;

      if (!isLearning && !wasLearning) {
        clearGameHelper();
        clearLetterPressHelper();

        store.dispatch(clearGame());
      }
    }
  });

  observeStore(store, selectLetterPresses, (letterPresses) => {
    const puzzle = selectPuzzle(store.getState());
    if (!puzzle.words?.length) {
      // There is no puzzle, so nothing else to do
      return;
    }

    logInfo({ message: "Observing letterPresses changes" });

    const isLearning = selectIsLearning(store.getState());

    if (letterPresses.length <= 1) {
      return;
    }

    if (letterPresses.length === 1) {
      const learningIndex = selectLearningGameIndex(store.getState());

      store.dispatch(
        track({
          eventName: "press_letter",
          properties: {
            gameIndex: isLearning ? learningIndex : puzzle.gameIndex,
          },
        })
      );
    }

    store.dispatch(postProgress());
  });
};
