import { useEffect } from "react";
import { selectDailyStats, selectGameIndex } from "../app/gameSlice";
import { useAppDispatch, useAppSelector } from "../hooks";
import StatsPanel from "./StatsPanel";
import { loadTodaysGame } from "../helpers/puzzles";
import Button from "./Button";
import { useNavigate } from "react-router-dom";
import { PLAY_PATH } from "../constants/paths";
import ShareButton, { ShareMessageType } from "./ShareButton";
import { useMessage } from "../hooks/useMessage";

const StatsScreen = () => {
  const stats = useAppSelector(selectDailyStats);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const asOfPrefix = useMessage("stats_screen_as_of_prefix").body;

  const asOfText =
    !stats || stats.latestGameNumber === 0
      ? ""
      : `${asOfPrefix}${stats.latestGameNumber}`;

  const todaysGameIndex = useAppSelector(selectGameIndex);

  const playedTodays = stats && stats.latestGameNumber === todaysGameIndex;

  const messagePlayTodaysPuzzle = useMessage(
    "stats_screen_play_todays_puzzle"
  ).body;

  useEffect(() => {
    if (todaysGameIndex === 0) {
      // load the latest game
      loadTodaysGame(dispatch);
    }
  }, [dispatch, stats, todaysGameIndex]);

  return (
    <div className="m-5">
      <h1 className="text-lg font-medium mb-2">
        {useMessage("stats_screen_title").body} {asOfText}
      </h1>
      <StatsPanel obfuscate={false} />
      <div className="text-center mt-5">
        {playedTodays && (
          <ShareButton messageType={ShareMessageType.fromStats} />
        )}
        {!playedTodays && (
          <Button onClick={() => navigate(PLAY_PATH)}>
            {messagePlayTodaysPuzzle}
          </Button>
        )}
      </div>
    </div>
  );
};

export default StatsScreen;
