import { useEffect, useState } from "react";
import {
  selectRemainingLetters,
  selectGameIndex,
  selectIsLearning,
  selectGameStatus,
  selectPuzzleLetters,
  selectShowSanta,
} from "../app/gameSlice";
import { isVowel } from "../helpers/letters";
import { useAppDispatch, useAppSelector } from "../hooks";
import Theme from "./Theme";
import GameState from "../types/GameState";
import { clearTitle, setTitle } from "../app/pageSlice";
import LivesIndicator from "./LivesIndicator";
import GameResult from "./GameResult";
import ShareButton from "./ShareButton";
import Countdown from "./Countdown";
import { getMidnightTonight } from "../helpers/dates";
import WordGrid from "./WordGrid";
import Keyboard from "./Keyboard";
import { Santa } from "./Santa";
import SpinnerIcon from "./icons/SpinnerIcon";
import { LearningGameSummary } from "./LearningGameSummary";
import { logInfo } from "../helpers/tracing";
import { Reveal } from "./Reveal";
import { StatsTimer } from "./stats/StatsTimer";
import StatsRank from "./stats/StatsRank";
import { StatsSummary } from "./stats/StatsSummary";
import { selectStats } from "../app/statsSlice";
import { getPlatform } from "../helpers/system";

type Props = {
  onKeyPress: (input: string) => void;
  loadNextGame: () => void;
  isLoading: boolean;
};

enum EndAnimationPhase {
  None = 0,
  MinimiseWordGrid = 1,
  Theme = 2,
  Result = 3,
  PuzzleStats = 4,
  Countdown = 5,
  StreakStats = 6,
}

const GameContent = ({ onKeyPress, loadNextGame, isLoading }: Props) => {
  logInfo({ message: "Rendering game content" });
  const [endAnimationPhase, setEndAnimationPhase] = useState<EndAnimationPhase>(
    EndAnimationPhase.None
  );
  const [keyboardHidden, setKeyboardHidden] = useState(false);

  const status = useAppSelector(selectGameStatus);

  const dispatch = useAppDispatch();

  const puzzleIsLoaded = useAppSelector(selectPuzzleLetters)?.length > 0;

  const remainingLetters = useAppSelector(selectRemainingLetters);

  const onlyVowelsLeft = remainingLetters.every(isVowel);

  const isGameOver = status === GameState.SOLVED || status === GameState.LOST;

  const isLearning = useAppSelector(selectIsLearning);

  const gameIndex = useAppSelector(selectGameIndex);

  useEffect(() => {
    dispatch(
      setTitle({
        gameIndex: isLearning ? undefined : gameIndex,
        title: isGameOver ? "Game over" : "",
      })
    );

    return () => {
      dispatch(clearTitle());
    };
  }, [dispatch, isLearning, isGameOver, gameIndex]);

  const showSanta = useAppSelector(selectShowSanta);

  const currentGameUserStats = useAppSelector(selectStats);
  const statsLoaded =
    currentGameUserStats &&
    currentGameUserStats.gameIndex === gameIndex &&
    currentGameUserStats.userHasPlayed;

  const [canShowStats, setCanShowStats] = useState(false);

  useEffect(() => {
    let fallbackResultTimer: NodeJS.Timeout | null = null;
    const timers: NodeJS.Timeout[] = [];

    if (isGameOver) {
      switch (endAnimationPhase) {
        case EndAnimationPhase.None: {
          const delay = (remainingLetters.length + 1) * 150 + 700;

          timers.push(
            setTimeout(() => {
              setEndAnimationPhase(EndAnimationPhase.MinimiseWordGrid);
            }, delay)
          );
          break;
        }

        case EndAnimationPhase.MinimiseWordGrid: {
          timers.push(
            setTimeout(() => {
              setEndAnimationPhase(EndAnimationPhase.Theme);
            }, 300)
          );
          break;
        }

        case EndAnimationPhase.Theme: {
          timers.push(
            setTimeout(() => {
              setEndAnimationPhase(EndAnimationPhase.Result);
            }, 300)
          );
          break;
        }

        case EndAnimationPhase.Result: {
          // Stats showable starts as false

          // Wait 300ms, set them as showable, and move to PuzzleStats phase

          // If we don't have them within that time, keep showable false.
          // Keep the phase the same
          // Wait another 300ms, move to Countdown phase (this timeout will be cancelled if we get the stats before it finishes)
          // We will know we have them because redux will have updated for this gameIndex
          // If it returns before that next 300ms, then we can cancel that timer, set showable to true, and immedidately move on to PuzzleStats
          // If it returns after that next 300ms, keep showable as false.

          timers.push(
            setTimeout(() => {
              setCanShowStats(true); // This will show the stats when they are available

              if (statsLoaded) {
                // move to next phase because we have the stats
                setEndAnimationPhase(EndAnimationPhase.PuzzleStats);
              } else {
                fallbackResultTimer = setTimeout(() => {
                  setCanShowStats(false); // never be able to show even when they do show up
                  setEndAnimationPhase(EndAnimationPhase.Countdown);
                }, 300);
              }
            }, 300)
          );

          fallbackResultTimer = setTimeout(() => {
            console.log(
              "Presumably no stats loaded after 1000ms, so skipping it for now"
            );
            setEndAnimationPhase(EndAnimationPhase.PuzzleStats);

            setCanShowStats(false);
          }, 1000);

          break;
        }

        case EndAnimationPhase.PuzzleStats: {
          timers.push(
            setTimeout(() => {
              setEndAnimationPhase(EndAnimationPhase.Countdown);
            }, 300)
          );
          break;
        }

        case EndAnimationPhase.Countdown: {
          timers.push(
            setTimeout(() => {
              setEndAnimationPhase(EndAnimationPhase.StreakStats);
            }, 300)
          );
          break;
        }
      }
    } else if (!isGameOver) {
      setEndAnimationPhase(EndAnimationPhase.None);
      setKeyboardHidden(false);
    }

    if (
      endAnimationPhase === EndAnimationPhase.Result &&
      statsLoaded &&
      canShowStats
    ) {
      if (statsLoaded) {
        setEndAnimationPhase(EndAnimationPhase.PuzzleStats);
        if (fallbackResultTimer) {
          clearTimeout(fallbackResultTimer);
        }
      }
    }

    return () => {
      if (fallbackResultTimer) {
        clearTimeout(fallbackResultTimer);
      }
      timers.map((t) => clearTimeout(t));
    };
  }, [
    isGameOver,
    endAnimationPhase,
    remainingLetters.length,
    statsLoaded,
    canShowStats,
  ]);

  let bottomPadding: boolean = false;

  if (["ios", "ipad"].includes(getPlatform())) {
    bottomPadding = true;
  }

  return (
    <>
      {isLoading && (
        <div className="flex-auto flex items-center justify-center">
          <div className="text-center">
            <SpinnerIcon
              size={80}
              className="inline-block fill-slate-300 animate-spin"
            />
          </div>
        </div>
      )}
      {!isLoading && (
        <>
          <div
            className={`h-full max-h-full flex flex-col ${
              bottomPadding ? "pb-5" : ""
            }`}
          >
            <div className="flex-1 overflow-y-auto grid grid-rows-[auto_1fr]">
              {/* Make this a grid again */}
              <div id="top-matter">
                <div className="w-full z-20">
                  <div className="max-w-md mx-auto">
                    <div
                      className={`mt-2 mx-4 flex justify-between items-center`}
                    >
                      <div className="flex-1 h-5">
                        {puzzleIsLoaded &&
                          onlyVowelsLeft &&
                          status === GameState.IN_PROGRESS && (
                            <p className="text-sm font-bold">
                              Only vowels left!
                            </p>
                          )}
                      </div>

                      <div className="text-center">
                        {puzzleIsLoaded && (
                          <LivesIndicator className="flex-1" />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`flex ${
                  endAnimationPhase >= EndAnimationPhase.MinimiseWordGrid
                    ? "items-start"
                    : "items-center"
                } justify-center`}
              >
                <div
                  id="word-grid-container"
                  className={`${
                    isGameOver &&
                    endAnimationPhase >= EndAnimationPhase.MinimiseWordGrid
                      ? "items-top mt-3"
                      : "items-center"
                  } transition-all flex justify-center max-h-full`}
                >
                  <div className="w-full">
                    <div className="max-w-md mx-auto">
                      {/* Words */}
                      <WordGrid
                        isSmall={
                          isGameOver &&
                          endAnimationPhase >=
                            EndAnimationPhase.MinimiseWordGrid
                        }
                      />
                    </div>

                    {endAnimationPhase >= EndAnimationPhase.Theme && (
                      <Reveal>
                        <Theme />
                      </Reveal>
                    )}

                    {isLearning &&
                      endAnimationPhase >= EndAnimationPhase.Result && (
                        <Reveal>
                          <div className="text-center mt-1">
                            <LearningGameSummary />
                          </div>
                        </Reveal>
                      )}

                    {!isLearning &&
                      endAnimationPhase >= EndAnimationPhase.Result && (
                        <Reveal>
                          <GameResult className="text-center mt-1.5" />
                        </Reveal>
                      )}
                    {!isLearning &&
                      endAnimationPhase >= EndAnimationPhase.PuzzleStats && (
                        <Reveal>
                          <div className="text-xs text-center mb-2">
                            <StatsTimer />
                            {canShowStats && statsLoaded && <StatsRank />}
                          </div>
                        </Reveal>
                      )}
                    {!isLearning &&
                      endAnimationPhase >= EndAnimationPhase.Countdown && (
                        <Reveal className="text-center">
                          <Countdown
                            className="mb-2 text-xs"
                            targetDate={getMidnightTonight()}
                            onTimerEnd={loadNextGame}
                          />
                          <ShareButton />
                        </Reveal>
                      )}
                    {!isLearning &&
                      endAnimationPhase >= EndAnimationPhase.StreakStats && (
                        <Reveal>
                          <StatsSummary />
                        </Reveal>
                      )}
                  </div>
                </div>
              </div>
            </div>
            {!keyboardHidden && (
              <div
                id="keyboard-container"
                className={`transition-opacity ${
                  isGameOver &&
                  endAnimationPhase >= EndAnimationPhase.MinimiseWordGrid
                    ? " opacity-0"
                    : " opacity-100"
                }`}
                onTransitionEnd={(ev) => {
                  if (ev.target === ev.currentTarget) {
                    setKeyboardHidden(true);
                  }
                }}
              >
                <div className="w-full sticky bottom-0 z-10 bg-white dark:bg-slate-900">
                  <div className="max-w-md mx-auto">
                    <Keyboard
                      onKeyPress={onKeyPress}
                      disabled={
                        status === GameState.SOLVED || status === GameState.LOST
                      }
                      disableConsonants={onlyVowelsLeft}
                      gameOver={isGameOver}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          <div id="toast-container"></div>
          {showSanta && <Santa />}
        </>
      )}
    </>
  );
};

export default GameContent;
