import { v4 as uuid } from "uuid";

const getBuildHash = () =>
  import.meta.env.VITE_GIT_HASH.substring(0, 7) ?? "dev";

const getVersionNumber = () => APP_VERSION;

type Uid = string;

const createUid = () => {
  const userIdentifier = uuid();
  localStorage.setItem("uid", userIdentifier);
  return userIdentifier;
};

const getUid: () => Uid = () => {
  return localStorage.getItem("uid") ?? createUid();
};

export const replaceUid = (uid: string) => {
  localStorage.setItem("uid", uid);
  return uid;
};

export const getDeviceId: () => string = () => {
  let deviceId = localStorage.getItem("deviceId");

  if (!deviceId) {
    deviceId = uuid();
    localStorage.setItem("deviceId", deviceId);
  }

  return deviceId;
};

let cachedNativeAppVersion: [string, string] | null | undefined = undefined;

export const getNativeAppVersion = () => {
  if (cachedNativeAppVersion) {
    return cachedNativeAppVersion;
  }
  // get the useragent
  const userAgent = navigator.userAgent;
  // extract the HangFive version, if present
  const hangFiveVersionMatch = userAgent.match(
    /HangFive\/(\d+\.\d+\.\d+(?:\.\d+)?)/
  );
  const hangFiveVersion = hangFiveVersionMatch
    ? hangFiveVersionMatch[1]
    : undefined;

  console.log("userAgent", userAgent);

  // extract the operating system (Android or iOS)
  const osMatch = userAgent.match(/Android|iPhone OS|iPad OS/);
  const os = osMatch ? osMatch[0].toLowerCase() : "undefined";

  if (hangFiveVersion && os) {
    cachedNativeAppVersion = [
      os.toLowerCase().replace("iphone os", "ios").replace("ipad os", "ipad"),
      hangFiveVersion,
    ];
  } else {
    cachedNativeAppVersion = null;
  }

  return cachedNativeAppVersion;
};

export const isNative = () => {
  return getNativeAppVersion() !== null;
};

export type Platform =
  | "android-twa"
  | "android"
  | "ios-twa"
  | "ios"
  | "pwa-android"
  | "pwa-ios"
  | "pwa"
  | "web-android"
  | "web-ios"
  | "web"
  | "ipad";

export const getPlatform: () => Platform | string = () => {
  let platform: string | undefined =
    sessionStorage.getItem("platform") ?? undefined;

  const nativeAppVersion = getNativeAppVersion();

  console.log("nativeAppVersion", nativeAppVersion);

  if (nativeAppVersion) {
    return nativeAppVersion[0];
  }
  if (document.referrer.includes("android-app://com.zemobo.hangfive.twa")) {
    platform = "android-twa";
  } else if (window.matchMedia("(display-mode: standalone)").matches) {
    platform = "pwa";
  }

  if (!platform) {
    platform = "web";
  }

  if (platform === "pwa" || platform === "web") {
    const userAgent = navigator.userAgent;
    const isAndroid = userAgent.includes("Android");
    const isiOS = userAgent.includes("iPhone OS");
    const isIPad = userAgent.includes("iPad");

    if (isAndroid) {
      platform = `${platform}-android`;
    } else if (isiOS) {
      platform = `${platform}-ios`;
    } else if (isIPad) {
      platform = `${platform}-ipad`;
    }
  }

  sessionStorage.setItem("platform", platform);

  return platform;
};

export const getThumbprint = async () => {
  const userAgent = navigator.userAgent;
  const resolution = `${window.screen.width}x${window.screen.height}`;
  const colorDepth = window.screen.colorDepth;
  const pixelRatio = window.devicePixelRatio;

  const rawThumbprint = `${userAgent}${resolution}${colorDepth}${pixelRatio}`;
  const sha512Thumbprint = await sha512(rawThumbprint);

  return sha512Thumbprint;
};

const sha512 = async (rawThumbprint: string): Promise<string> => {
  const encoder = new TextEncoder();
  const data = encoder.encode(rawThumbprint);
  const hashBuffer = await crypto.subtle.digest("SHA-512", data);
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");
  return hashHex;
};

export { getBuildHash, getVersionNumber, getUid };
export type { Uid };
