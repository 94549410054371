type LegacyUserStats = {
  totalGames: number;
  winPercentage: number;
  averageCorrectLettersPercentage: number;
  averageLivesUsed: number;
  averagePaidVowelsUsed: number;
  maxWinStreak: number;
  currentWinStreak: number;
  latestGameNumber: number;
  maxPlayStreak: number;
  currentPlayStreak: number;
  statsVersion: 1;
};

type DailyUserStats = {
  playCount: number;
  winStreak: number;
  playStreak: number;
  winCount: number;
  maxWinStreak: number;
  maxPlayStreak: number;
  latestGameNumber: number;
};

type UserStats = {
  daily?: DailyUserStats;
  statsVersion: 2;
  total: {
    playCount: number;
    winCount: number;
  };
};

const mapLegacyStats = (legacyStats: LegacyUserStats): UserStats => {
  return {
    daily: {
      latestGameNumber: legacyStats.latestGameNumber,
      maxPlayStreak: legacyStats.maxPlayStreak,
      maxWinStreak: legacyStats.maxWinStreak,
      playCount: legacyStats.totalGames,
      playStreak: legacyStats.currentPlayStreak,
      winCount: Math.round(
        (legacyStats.winPercentage * legacyStats.totalGames) / 100
      ),
      winStreak: legacyStats.currentWinStreak,
    },
    statsVersion: 2,

    total: {
      playCount: legacyStats.totalGames,
      winCount: Math.round(
        (legacyStats.winPercentage * legacyStats.totalGames) / 100
      ),
    },
  };
};

export default UserStats;

export { mapLegacyStats };
export type { LegacyUserStats, DailyUserStats };
